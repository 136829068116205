const fileToBase64 = (file: File) =>
	new Promise((resolve, reject) => {
		let reader = new FileReader();

		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});

export default fileToBase64;
