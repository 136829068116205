import styled, { css } from 'styled-components';
import { StyledButton } from '../../ui/Button/styles';
import { maxQuery, minMaxQuery } from '../../utilities';
import { StyledPagination } from '../../ui/Pagination/styles';
import { StyledSearchBar } from '../../ui/SearchBar/styles';

export const Container = styled.div`
	padding: 2.5rem 6.25rem;
	padding: 2.5rem clamp(2rem, -0.3611rem + 7.5556vw, 6.25rem);

	.header {
		display: flex;
		align-items: flex-start;
		gap: 1.5rem;
		flex-wrap: wrap;

		${maxQuery('400px')} {
			gap: 1rem;
		}

		> div:first-child {
			margin-right: auto;

			${maxQuery('md')} {
				flex-basis: 100%;
			}

			h4 {
				color: #11071a;
				font-family: 'Urbanist', sans-serif;
				font-size: 1.5rem;
				font-size: clamp(1rem, 0.7222rem + 0.8889vw, 1.5rem);
				font-weight: 700;
				margin-bottom: 0.25rem;
				line-height: 120%;
			}
			p {
				color: #828282;
				font-size: 0.75rem;
				line-height: 120%;
			}
		}
		${StyledSearchBar} {
			${maxQuery('md')} {
				flex: 1;
			}
			${maxQuery('400px')} {
				flex-basis: 100%;
			}
		}
		${StyledButton} {
			padding: 0.5rem 1.5rem;
			min-height: 3.2375rem;

			span {
				display: flex;
				align-items: center;
				gap: 0.5rem;
			}
		}
	}
	.controls {
		display: flex;
		flex-wrap: wrap;
		gap: 2rem;
		gap: clamp(1.25rem, 0rem + 4vw, 2rem);
		margin: 1.5rem 0 1.87rem;
		margin: clamp(1rem, 0.1667rem + 2.6667vw, 1.5rem) 0 clamp(1rem, -0.45rem + 4.64vw, 1.87rem);

		button {
			background-color: transparent;
			color: #bbbaba;
			font-size: 1rem;
			font-size: clamp(0.875rem, 0.6667rem + 0.6667vw, 1rem);
			font-weight: 600;
			border-bottom: 2px solid transparent;
			padding: 0.75rem 1.5rem 0.75rem 0;
			padding: clamp(0.5rem, 0.0833rem + 1.3333vw, 0.75rem) clamp(0rem, -2.5rem + 8vw, 1.5rem) clamp(0.5rem, 0.0833rem + 1.3333vw, 0.75rem) 0;

			&.active {
				color: #12091b;
				border-bottom: 2px solid #4060ea;
			}
		}
	}
	center {
		height: calc(100vh - 24rem);
		display: grid;
		place-items: center;
		background-color: #fff;
		border-radius: 0.125rem;
		padding: 1rem;
	}
	.empty-state {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: calc(100vh - 24rem);
		background-color: #fff;
		border-radius: 0.125rem;
		padding: 1rem;

		p {
			color: #1e1e1e;
			font-size: 0.875rem;
			font-weight: 600;
			line-height: 135%;
			margin-top: 1rem;
		}
		span {
			text-transform: capitalize;
		}
	}
	.projects {
		display: grid;
		background-color: #fff;
		padding: 1.25rem 3rem;
		border-radius: 0.25rem;

		${maxQuery('xl')} {
			background-color: transparent;
			gap: 1rem;
			padding: 0;
		}
	}
	${StyledPagination} {
		justify-content: center;
		margin-top: 3rem;
	}
`;

export const StyledProject = styled.div`
	display: grid;
	grid-template-columns: 23% 21% 20% 25% 11%;
	padding: 1.25rem 0;

	:not(:last-child) {
		border-bottom: 1px solid rgba(150, 150, 150, 0.11);

		${maxQuery('xl')} {
			border-bottom: none;
		}
	}

	${maxQuery('xl')} {
		grid-template-columns: 1fr 1fr;
		background-color: #fff;
		padding: 0.5rem 1.5rem;
		border-radius: 0.25rem;
	}
	${maxQuery('360px')} {
		grid-template-columns: 1fr;
	}

	> div {
		:not(:last-child) {
			border-right: 1px solid rgba(206, 206, 206, 0.4);

			${maxQuery('xl')} {
				order: 1;
				border-right: none;
			}
		}
		:nth-child(1) {
			display: flex;
			gap: 0.5rem;
			align-items: center;
			flex-wrap: wrap;

			${maxQuery('xl')} {
				padding: 1rem 0;
				border-bottom: 1px solid rgba(206, 206, 206, 0.4);
				grid-column: 1/3;
			}
			${minMaxQuery('sm', 'xl')} {
				position: relative;
				padding-left: 2.5rem;
				padding-left: clamp(2.5rem, -3.5rem + 16vw, 8.5rem);
			}
			${maxQuery('360px')} {
				grid-column: 1/2;
				justify-content: center;
			}

			span {
				background: #fcf8f3;
				color: #c08030;
				font-size: 0.75rem;
				font-weight: 700;
				line-height: 120%;
				text-transform: uppercase;
				border-radius: 6.25rem;
				padding: 0.3125rem 0.625rem;

				${({ $sector }) =>
					$sector?.toLowerCase() === 'gaming' &&
					css`
						background: #ebf9e9;
						color: #42b431;
					`}
				${({ $sector }) =>
					$sector?.toLowerCase() === 'payment' &&
					css`
						background: #e5f3fe;
						color: #2299f6;
					`}
			}
		}
		:nth-child(2),
		:nth-child(3) {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 0.5rem;

			${maxQuery('xl')} {
				grid-row: 3;
				padding: 1rem 0;
			}
			${maxQuery('360px')} {
				grid-row: unset;
				justify-content: center !important;
				padding: 1rem 0;
				border-bottom: 1px solid rgba(206, 206, 206, 0.4);
			}

			b,
			span {
				display: block;
				text-align: center;
			}
			b {
				font-size: clamp(0.8125rem, 0.2917rem + 1.6667vw, 1.125rem);
			}
			span {
				color: rgba(15, 15, 15, 0.46);
				font-size: 1.0625rem;
				font-size: clamp(0.8125rem, 0.3958rem + 1.3333vw, 1.0625rem);
				font-weight: 500;
			}
		}
		:nth-child(2) {
			${maxQuery('xl')} {
				order: 4;
			}
			${maxQuery('sm')} {
				justify-content: flex-start;
			}
		}
		:nth-child(3) {
			${maxQuery('xl')} {
				order: 5;
			}
			${maxQuery('sm')} {
				justify-content: flex-end;
			}
		}
		:nth-child(4) {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 3rem;
			gap: clamp(1rem, -2.3333rem + 10.6667vw, 3rem);

			${maxQuery('xl')} {
				order: 2;
				grid-row: 2;
				border-bottom: 1px solid rgba(206, 206, 206, 0.4);
			}
			${maxQuery('sm')} {
				justify-content: flex-start;
			}
			${maxQuery('360px')} {
				grid-row: unset;
				justify-content: center;
				padding: 1rem 0;
			}

			> span {
				display: flex;
				align-items: center;
				gap: 0.5rem;
				color: #323232;
				font-size: 1.25rem;
				font-size: clamp(0.8125rem, 0.0833rem + 2.3333vw, 1.25rem);
				font-weight: 600;
				line-height: 110%;
				letter-spacing: -0.03125rem;

				:nth-child(1) path {
					fill: #4060ea;
				}

				span {
					display: block;
					position: relative;
					width: 1.25rem;
					height: 1.25rem;
					border-radius: 50%;
					background-image: conic-gradient(
						#fff 0deg,
						#fff ${({ $percent }) => 360 - ($percent / 100) * 360}deg,
						#4060ea ${({ $percent }) => 360 - ($percent / 100) * 360}deg,
						#4060ea 360deg
					);

					::before {
						content: '';
						border-radius: 50%;
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						background-image: radial-gradient(
							#fff 0.4rem,
							#4060ea 0.4rem,
							#4060ea 0.45rem,
							transparent 0.45rem,
							transparent 0.57rem,
							#4060ea 0.57rem
						);
					}
				}
			}
		}
		:nth-child(5) {
			display: flex;
			align-items: center;
			justify-content: flex-end;

			${maxQuery('xl')} {
				order: 3;
				padding: 1rem;
				border-bottom: 1px solid rgba(206, 206, 206, 0.4);
				grid-row: 2;
			}
			${minMaxQuery('sm', 'xl')} {
				justify-content: center;
			}
			${maxQuery('360px')} {
				grid-row: unset;
				order: 6;
				justify-content: center;
				border-bottom: none;
			}

			${StyledButton} {
				padding: 0.5rem 1rem;

				span {
					display: flex;
					align-items: center;
					gap: 0.5rem;
				}
			}
		}

		b {
			color: #323232;
			font-size: 1.125rem;
			font-size: clamp(1rem, 0.7917rem + 0.6667vw, 1.125rem);
			font-weight: 700;
			line-height: 132%;
		}
	}
`;
