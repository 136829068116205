import styled from 'styled-components';

export const Container = styled.div`
	&.mine .text-section {
		flex-direction: row-reverse;
		margin-left: auto;
	}
	&.mine .media-section {
		align-items: end;
	}
	:not(:last-child) .text-section {
		margin-bottom: 0.5rem;
	}
	.text-section {
		display: flex;
		gap: 0.625rem;
		align-items: flex-start;
		max-width: 80%;

		img {
			flex-shrink: 0;
			width: 1.25rem;
			height: auto;
			border-radius: 50%;
		}
		.initials {
			color: #4060ea;
			font-size: 1.125rem;
			font-weight: 700;
			line-height: 132%;
			width: 2.5rem;
			height: 2.5rem;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			background: #dae0fb;
			border: 2px solid #fff;
			flex-shrink: 0;

			display: none;
		}
		.text-con {
			/* flex: 1; */
			width: max-content;
			max-width: 500px;
			padding: 0.5rem 0.75rem;
			/* background-color: #d3e7d6; */
			background-color: #f5f6f9;
			border-radius: 8px 8px 8px 0;

			&.mine {
				background-color: #dae0fb;
				border-radius: 8px 8px 0 8px;
			}
		}
		/* .name-date-group {
		display: flex;
		justify-content: space-between;
		align-items: center;
		}
		.name {
			font-weight: 600;
			font-size: 0.875rem;
			line-height: 1.2;
			} */
		.date {
			color: #bbbaba;
			font-size: 0.75rem;
			line-height: 1.2;
			display: block;
			width: max-content;
			margin-left: auto;
		}
		.message-con {
			font-size: 0.875rem;
			margin-top: 0.375rem;

			ul,
			ol {
				padding: 0 1rem;
			}
			ul {
				list-style: disc;
			}
		}
		.name {
			display: -webkit-box;
			overflow: hidden;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
		}
	}

	.media-section {
		display: flex;
		flex-direction: column;
		margin-bottom: 1rem;

		> a {
			display: flex;
			position: relative;
			border-radius: 8px;
			border: 1px solid rgba(229, 229, 229, 0.7);
			background: #fff;
			width: 12rem;
			align-items: center;

			:not(:last-child) {
				margin-bottom: 0.5rem;
			}

			.preview-img {
				border-radius: 0.25rem 0 0 0.25rem;
				height: 48px;
				width: 48px;
			}
			div:first-child {
				border-radius: 0.25rem 0 0 0.25rem;
				border: 1px solid rgba(51, 51, 51, 0.1);
				background: rgba(227, 232, 252, 0.1);
				padding: 0 0.75rem;
				display: grid;
				place-items: center;
				height: 48px;
			}

			div:nth-child(2) {
				padding: 0rem 1rem;
				overflow: hidden;
				text-align: left;

				p {
					color: #11071a;
					font-size: 0.75rem;
					font-weight: 600;
					line-height: 1;

					display: -webkit-box;
					overflow: hidden;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;
				}
				span {
					color: #1e1e1e;
					font-size: 0.625rem;
					line-height: 1;
					display: block;
					margin-top: 0.45rem;
				}
			}
		}
	}
`;
