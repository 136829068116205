import styled from 'styled-components';

export const Container = styled.div`
	background: rgba(0, 0, 0, 0.5);
	display: grid;
	place-items: center;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 99;

	> div {
		border-radius: 0.25rem;
		background: #fff;
		padding: 0 2rem 2rem;
		padding: 0 clamp(1.5rem, 1.2222rem + 0.8889vw, 2rem) 2rem;
		width: 33.5rem;
		max-width: calc(100vw - 2rem);
		max-height: calc(100vh - 2rem);
		overflow: auto;

		.header {
			position: sticky;
			top: 0;
			background-color: #fff;
			z-index: 2;
			padding-top: 2rem;

			h2 {
				text-align: center;
				font-size: 2rem;
				font-size: clamp(1.75rem, 1.6111rem + 0.4444vw, 2rem);
				margin-top: 0.5rem;
				color: #070d19;
			}
			h2 + p {
				color: #a8a8a8;
				font-size: 1rem;
				font-size: clamp(0.75rem, 0.6111rem + 0.4444vw, 1rem);
				line-height: 120%;
				margin: 0.5rem 0 1.5rem;
				text-align: center;
			}
		}
		.header svg,
		> svg {
			margin-left: auto;
			display: block;
			cursor: pointer;
			width: 1.25rem;
			width: clamp(1rem, 0.8611rem + 0.4444vw, 1.25rem);

			:active {
				outline: none;
			}
		}
		article {
			border-radius: 0.25rem;
			border: 1px solid #dde0e2;
			padding: 1rem;

			&.top {
				display: grid;
				grid-template-columns: 1fr 1fr;

				> div {
					:nth-child(1),
					:nth-child(2) {
						padding-bottom: 1.125rem;
						border-bottom: 1px solid #dde0e2;
					}
					:nth-child(3),
					:nth-child(4) {
						padding-top: 1.125rem;
					}
				}
				> div:nth-child(even) {
					text-align: right;
				}

				p {
					color: #757575;
				}
			}
			&.bottom {
				display: grid;
				grid-template-columns: 1fr 1fr;

				> div {
					:nth-child(1) {
						border-right: 1px solid #dde0e2;
					}
					:nth-child(2) {
						text-align: right;
					}
				}
			}
			button {
				background-color: transparent;
				color: #4060ea;
			}
			b {
				display: block;

				:first-child {
					margin-bottom: 0.25rem;
				}
			}
			p {
				font-size: 0.875rem;
				line-height: 140%;

				:first-child {
					margin-bottom: 0.25rem;
				}
			}
		}
		h4 {
			font-family: 'Urbanist', sans-serif;
			font-size: 1.125rem;
			font-weight: 600;
			line-height: 120%;
			margin-bottom: 0.75rem;
		}
	}
`;
