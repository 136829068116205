import styled from 'styled-components';
import { StyledLoader } from '../../../../ui/Loader/styles';

export const Container = styled.div`
	/* height: 87px; */
	/* max-height: 610px; */
	min-height: 100px;
	overflow: auto;
	position: relative;
	display: flex;
	flex-direction: column;
	border-top: 1px solid #ebebeb;
	border-left: 1px solid transparent;
	border-bottom: 1px solid transparent;
	border-right: 1px solid transparent;
	/* border: 1px solid rgba(0, 0, 0, 0); */

	&.focused {
		border-top: 1px solid rgba(0, 0, 0, 0.5);
		border-left: 1px solid rgba(0, 0, 0, 0.5);
		border-bottom: 1px solid rgba(0, 0, 0, 0.5);
		border-right: 1px solid rgba(0, 0, 0, 0.5);
		border-radius: 0.25rem;
	}

	> div:first-child {
		max-height: 30vh;
		overflow: hidden;
	}
	.tiptap {
		padding: 1rem 1.5rem;
		border-color: transparent;
		width: 100%;
		resize: none;
		outline: none;
		max-height: 100%;
		overflow: auto;
		scrollbar-width: thin;
		font-size: 0.875rem;

		ul,
		ol {
			padding: 0 1rem;
		}
		ul {
			list-style: disc;
		}
		p.is-editor-empty:first-child {
			/* background-color: red; */

			::before {
				color: #adb5bd;
				content: attr(data-placeholder);
				float: left;
				height: 0;
				pointer-events: none;
			}
		}
	}
	button {
		padding: 0;
		background-color: transparent;
		border: none;
		display: flex;
		align-items: center;
		padding: 0.25rem;

		:disabled {
			opacity: 0.7;
			cursor: not-allowed;
		}

		&.is-active {
			background: rgba(64, 96, 234, 0.1);
			border-radius: 2px;

			path {
				fill: rgba(64, 96, 234, 0.8);
			}
		}

		${StyledLoader} {
			width: 1.125rem;
			height: 1.125rem;
			border-width: 0.1125rem;
		}
	}
	.attachment-con {
		display: flex;
		align-items: center;
		padding: 0.75rem 1rem 0.25rem;
		margin-bottom: 0.5rem;
		overflow-x: auto;
		scrollbar-width: thin;
		gap: 1rem;

		.attachment {
			position: relative;

			:hover {
				button {
					display: flex;
				}
			}
			button {
				position: absolute;
				display: none;
				z-index: 2;
				top: 0;
				right: 0;
				background-color: #e2e2e2;
				border: 1px solid #00000033;
				border-radius: 50%;
				transform: translate(16%, -36%);

				:hover {
					background-color: #fff;
				}

				svg {
					width: 0.625rem;
					height: 0.625rem;

					path {
						fill: #000;
					}
				}
			}
			img.preview-img {
				width: 4rem;
				height: 4rem;
				border-radius: 8px;
			}
			> div {
				display: flex;
				position: relative;
				border-radius: 8px;
				border: 1px solid rgba(229, 229, 229, 0.7);
				background: #fff;
				width: 15.5rem;
				align-items: center;

				div:first-child {
					border-radius: 0.25rem;
					border: 1px solid rgba(51, 51, 51, 0.1);
					background: rgba(227, 232, 252, 0.1);
					padding: 0 0.75rem;
					display: grid;
					place-items: center;
					height: 58px;
				}

				div:nth-child(2) {
					padding: 0rem 1rem;
					overflow: hidden;
					text-align: left;

					p {
						color: #11071a;
						font-size: 0.875rem;
						font-weight: 600;
						line-height: 130%;

						display: -webkit-box;
						overflow: hidden;
						-webkit-line-clamp: 1;
						-webkit-box-orient: vertical;
					}
					span {
						color: #1e1e1e;
						font-size: 0.75rem;
						line-height: 158%;
					}
				}
			}
		}
	}
	.bottom-con {
		display: flex;
		padding: 0 1.5rem 1rem;
		justify-content: space-between;

		> div {
			display: flex;
			align-items: center;
			gap: 1rem;
		}
		.divider {
			border-left: 1px solid #ebebeb;
			height: 1.25rem;
		}
		.attachments {
			display: none;
		}
	}

	/* > * + * {
		margin-top: 0.75em;
	}

	ul,
	ol {
		padding: 0 1rem;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		line-height: 1.1;
	}

	code {
		background-color: rgba(#616161, 0.1);
		color: #616161;
	}
	

	pre {
		background: #0d0d0d;
		color: #fff;
		font-family: 'JetBrainsMono', monospace;
		padding: 0.75rem 1rem;
		border-radius: 0.5rem;

		code {
			color: inherit;
			padding: 0;
			background: none;
			font-size: 0.8rem;
		}
	}

	img {
		max-width: 100%;
		height: auto;
	}

	blockquote {
		padding-left: 1rem;
		border-left: 2px solid rgba(#0d0d0d, 0.1);
	}

	hr {
		border: none;
		border-top: 2px solid rgba(#0d0d0d, 0.1);
		margin: 2rem 0;
	} */
`;
