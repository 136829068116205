import styled from 'styled-components';

export const Container = styled.div`
	background: rgba(0, 0, 0, 0.5);
	display: grid;
	place-items: center;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 99;

	> div {
		border-radius: 0.25rem;
		background: #fff;
		padding: 2rem;
		padding: 2rem clamp(1.5rem, 1.2222rem + 0.8889vw, 2rem);
		padding-top: 0;
		width: 37.9375rem;
		max-width: calc(100vw - 2rem);
		max-height: calc(100vh - 2rem);
		height: clamp(51.75rem, calc(100vh - 2rem), 51.75rem);
		overflow: auto;
		position: relative;

		.header {
			position: sticky;
			top: 0;
			background-color: #fff;
			z-index: 2;
			padding-top: 2rem;
		}

		header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-bottom: 1.12rem;

			h2 {
				color: #070d19;
				font-size: 1.5rem;
				font-size: clamp(1.2rem, 1.0333rem + 0.5333vw, 1.5rem);
				font-weight: 600;
				line-height: normal;
			}
			svg {
				display: block;
				cursor: pointer;
				width: 1.25rem;
				width: clamp(1rem, 0.8611rem + 0.4444vw, 1.25rem);

				:active {
					outline: none;
				}
			}
		}
		hr {
			border-top: 1px solid #ebebeb;
		}
		nav {
			display: flex;
			align-items: center;
			gap: 0.5rem;
			padding: 0.75rem 0.5rem 0.75rem 0;

			> svg {
				display: block;
				margin-left: auto;
			}
			button {
				padding: 0.625rem 1.125rem;
				border-radius: 0.3125rem;
				color: #2f2f2f;
				font-size: 1rem;
				font-weight: 500;
				line-height: 120%;
				background: transparent;
				min-width: 5.5rem;

				:hover,
				:focus,
				:active,
				&.active {
					background: #f6f7fe;
				}
			}
		}
		.empty-state {
			text-align: center;
			font-weight: 600;
		}
		center,
		.empty-state {
			padding: 3rem 0;
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			transform: translateY(-1rem);
		}
		.content {
			.group {
				:first-child {
					padding-top: 1rem;
				}
				:last-child .notification:last-child {
					padding-bottom: 0;
				}

				.day {
					color: #757575;
					text-align: center;
					font-size: 0.875rem;
					font-weight: 500;
					position: relative;

					::before {
						content: '';
						position: absolute;
						top: 50%;
						left: 0;
						right: 0;
						border-top: 1px solid #ebebeb;
					}

					span {
						padding: 0.9rem;
						background-color: #fff;
						position: relative;
						z-index: 1;
						width: max-content;
					}
				}
			}
			.notification {
				display: flex;
				gap: 0.5rem;
				padding: 1.37rem 1.75rem 1.37rem 0;
				border-bottom: 1px solid #ebebeb;
				position: relative;
				cursor: pointer;

				:last-child {
					border-bottom: unset;
				}

				&.unread::after {
					content: '';
					position: absolute;
					top: 50%;
					right: 0.5rem;
					transform: translateY(-50%);
					width: 0.75rem;
					height: 0.75rem;
					background-color: #4060ea;
					border-radius: 50%;
				}

				img {
					width: 2.0625rem;
					height: 2.0625rem;
				}

				p {
					color: #757575;
					font-size: 0.875rem;
					line-height: 135%;
					margin-bottom: 0.5rem;

					b,
					strong {
						color: #070d19;
						font-weight: 600;
					}
				}
				p + span {
					color: #757575;
					font-size: 0.875rem;
					font-weight: 400;
					line-height: 135%;
				}
			}
		}
	}
`;
