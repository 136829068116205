import { EditorProvider, useCurrentEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import { useEffect, useRef, useState } from 'react';
import { Container } from './styles';
import {
	CloseMenu,
	FileDoc,
	// MessageAt,
	// MessageEmoji,
	// MessagePin,
	MessageBold,
	MessageBulletList,
	MessageItalic,
	MessageNumberList,
	MessagePin,
	MessageSend,
	MessageStrikeThrough,
} from '../../../../assets/svgs';
import { useNotify, useSendMessage } from '../../../../hooks';
import { Loader } from '../../../../ui';
import { fileToBase64 } from '../../../../utilities';

const extensions = [
	Placeholder.configure({
		placeholder: 'Write a message....',
	}),
	StarterKit.configure({
		bulletList: {
			keepMarks: true,
			keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
		},
		orderedList: {
			keepMarks: true,
			keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
		},
	}),
];

const content = '';

const RichTextArea = ({ receiverId, scrollToMessageEnd }) => {
	const [isFocused, setIsFocused] = useState(false);

	return (
		<Container className={isFocused ? 'focused' : ''}>
			<EditorProvider
				extensions={extensions}
				content={content}
				slotAfter={<MenuBar receiverId={receiverId} setIsFocused={setIsFocused} scrollToMessageEnd={scrollToMessageEnd} />}
			></EditorProvider>
		</Container>
	);
};

const MenuBar = ({ receiverId, setIsFocused, scrollToMessageEnd }) => {
	const { editor } = useCurrentEditor();
	const { mutate: sendMessage, isLoading } = useSendMessage();
	const notify = useNotify();
	const inputRef = useRef(null);
	const [attachmentFiles, setAttachmentFiles] = useState([]);

	const handleChange = async (event) => {
		const oneMB = 1048576;
		const maxSize = oneMB * 10;

		if ([...event.target.files].reduce((acc, file) => acc || file.size > maxSize, false)) {
			notify({
				message:
					[...event.target.files].length > 1
						? `One or more of those files is too large and cannot be uploaded. The limit is ${maxSize / oneMB} MB`
						: `That file is too large and cannot be uploaded. The limit is ${maxSize / oneMB} MB`,
				status: 'error',
				variant: 'minor',
				toastOptions: { toastId: 'file_size', position: 'bottom-center' },
			});
		}

		let filesWithinLimit = [...event.target.files].filter((file) => file.size <= maxSize);

		filesWithinLimit = await Promise.all(
			filesWithinLimit.map(async (file) => {
				const url = file.name;
				const extension = url.substring(url.lastIndexOf('.') + 1).toLowerCase();
				let preview;

				if (extension === 'gif' || extension === 'png' || extension === 'jpeg' || extension === 'jpg') {
					preview = await fileToBase64(file);
				}

				return { preview, file };
			})
		);
		setAttachmentFiles((prev) => [...prev, ...filesWithinLimit]);

		event.target.value = '';
	};

	useEffect(() => {
		setIsFocused(editor?.isFocused || false);
	}, [editor?.isFocused, setIsFocused]);

	if (!editor) {
		return null;
	}

	return (
		<>
			{attachmentFiles.length ? (
				<div className="attachment-con">
					{attachmentFiles.map(({ preview, file }, index) =>
						preview ? (
							<div key={index} className="attachment">
								<button
									title="Remove Item"
									onClick={() => setAttachmentFiles((prev) => prev.filter((_, i) => i !== index))}
								>
									<CloseMenu />
								</button>
								<img src={preview} alt={file.name} className="preview-img" />
							</div>
						) : (
							<div key={index} className="attachment">
								<button
									title="Remove Item"
									onClick={() => setAttachmentFiles((prev) => prev.filter((_, i) => i !== index))}
								>
									<CloseMenu />
								</button>
								<div>
									<div>
										<FileDoc />
									</div>
									<div>
										<p>{file.name}</p>
										<span>Document</span>
									</div>
								</div>
							</div>
						)
					)}
				</div>
			) : null}

			<div className="bottom-con">
				<div>
					<button
						type="button"
						onClick={() => editor.chain().focus().toggleBold().run()}
						disabled={!editor.can().chain().focus().toggleBold().run()}
						className={editor.isActive('bold') ? 'is-active' : ''}
					>
						<MessageBold />
					</button>
					<button
						type="button"
						onClick={() => editor.chain().focus().toggleItalic().run()}
						disabled={!editor.can().chain().focus().toggleItalic().run()}
						className={editor.isActive('italic') ? 'is-active' : ''}
					>
						<MessageItalic />
					</button>
					<button
						type="button"
						onClick={() => editor.chain().focus().toggleBulletList().run()}
						className={editor.isActive('bulletList') ? 'is-active' : ''}
					>
						<MessageBulletList />
					</button>
					<button
						type="button"
						onClick={() => editor.chain().focus().toggleOrderedList().run()}
						disabled={isLoading}
						className={editor.isActive('orderedList') ? 'is-active' : ''}
					>
						<MessageNumberList />
					</button>
					<button
						type="button"
						onClick={() => editor.chain().focus().toggleStrike().run()}
						disabled={!editor.can().chain().focus().toggleStrike().run()}
						className={editor.isActive('strike') ? 'is-active' : ''}
					>
						<MessageStrikeThrough />
					</button>
				</div>
				<input type="file" ref={inputRef} className="attachments" multiple onChange={handleChange} />
				<div>
					<button type="button" onClick={() => inputRef.current.click()}>
						<MessagePin />
					</button>
					<div className="divider"></div>
					<button
						type="submit"
						disabled={isLoading || editor.getText()?.trim()?.length === 0}
						onClick={() => {
							if (!editor.getText()?.trim()?.length && !attachmentFiles.length) return;

							const axiosFormData = new FormData();

							axiosFormData.append('text', editor.getHTML());
							axiosFormData.append('receiver', receiverId);
							for (let i = 0; i < attachmentFiles.length; i++) {
								axiosFormData.append('media', attachmentFiles[i].file);
							}

							sendMessage(axiosFormData, {
								onSuccess: () => {
									editor.commands.clearContent();
									scrollToMessageEnd();
									setAttachmentFiles([]);
								},
								onError: (error) =>
									notify({
										message: 'Failed to send message',
										status: 'error',
										variant: 'minor',
										toastOptions: { toastId: 'send_message_error', position: 'bottom-center' },
									}),
							});
						}}
					>
						{isLoading ? <Loader /> : <MessageSend />}
					</button>
				</div>
			</div>
		</>
	);
};

export default RichTextArea;
