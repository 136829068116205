import { useQueries, useQuery } from '@tanstack/react-query';
import { useAxiosPrivate } from './useAxiosPrivate';
import { Appurls, DEV_EMAILS } from '../constants';
// import { useEffect, useState } from 'react';
// import { getDateTime } from '../utilities';

export const useGetMainDashboardStats = () => {
	const axiosPrivate = useAxiosPrivate();

	return useQueries({
		queries: [
			{
				queryFn: () => axiosPrivate.get(Appurls.dashboard.overview_stats).then((response) => response?.data),
				queryKey: ['dashboard_overview_stats'],
			},
			{
				queryFn: () => axiosPrivate.get(Appurls.dashboard.user_overview_stats).then((response) => response?.data),
				queryKey: ['dashboard_user_overview_stats'],
			},
		],
	});
};

export const useGetBusinessStats = ({ extraQuery = '' }) => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: () => axiosPrivate.get(`${Appurls.dashboard.dashboard_business_stats}?${extraQuery}`).then((response) => response.data),
		queryKey: ['dashboard-business-stats', extraQuery],
	});
};

export const useGetInternStats = ({ extraQuery = '' }) => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: () => axiosPrivate.get(`${Appurls.dashboard.dashboard_intern_stats}?${extraQuery}`).then((response) => response.data),
		queryKey: ['dashboard-intern-stats', extraQuery],
	});
};

export const useGetDashboardStats = () => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: () => axiosPrivate.get(Appurls.dashboard.dashboard_stats).then((response) => response.data),
		queryKey: ['dashboard-stats'],
	});
};

export const useGetDashboardInstitutions = ({ page = 1, page_size = 5, search, enabled = false, extraQuery = '' }) => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: () =>
			axiosPrivate
				.get(`${Appurls.dashboard.institutions}?page=${page}&page_size=${page_size}&search=${search}${extraQuery}`)
				.then((response) => response.data),
		queryKey: ['dashboard-users-institutions', { page, page_size, search, extraQuery }],
		enabled,
	});
};
export const useGetDashboardInstitution = ({ institution_id }) => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: () => axiosPrivate.get(Appurls.dashboard.get_institution(institution_id)).then((response) => response.data),
		queryKey: ['dashboard-user-institution', { institution_id }],
	});
};

export const useGetDashboardUsers = ({ page = 1, page_size = 5, search, user = 'businesses', extraQuery = '' }) => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: () =>
			axiosPrivate
				.get(
					`${
						user === 'businesses' ? Appurls.dashboard.businesses : Appurls.dashboard.interns
					}?page=${page}&page_size=${page_size}&search=${search}${extraQuery}`
				)
				.then((response) =>
					process.env.NODE_ENV === 'production'
						? { ...response?.data, results: response?.data?.results?.filter((user) => !DEV_EMAILS.includes(user?.email)) }
						: response?.data
				),
		queryKey: ['dashboard-users', { page, page_size, search, user, extraQuery }],
	});
};

/*
export const useGetDashboardUsersForExport = ({ page = 1, page_size = 5, search, user = 'businesses', extraQuery = '' }) => {
	const axiosPrivate = useAxiosPrivate();
	const [isDone, setIsDone] = useState(false);
	const [data, setData] = useState([]);

	const { data: users, isError } = useGetDashboardUsers({
		page,
		page_size,
		search,
		user,
		extraQuery,
	});

	useEffect(() => {
		if (!users?.results) {
			console.log('returning - no users');
			return;
		}

		(async () => {
			for (let i = 0; i < users?.results?.length; i++) {
				const response = await axiosPrivate.get(
					user === 'businesses'
						? Appurls.dashboard.get_businesses(users?.results?.[i]?.id)
						: Appurls.dashboard.get_interns(users?.results?.[i]?.id)
				);
				setData((prev) => [...prev, response.data]);
				await new Promise((res) => setTimeout(res, 100));
			}

			setIsDone(true);
		})();
	}, [axiosPrivate, user, users?.results]);

	if (!isDone) {
		return { data: undefined, isLoading: isError ? false : true };
	} else {
		return {
			data: data?.map((user) => ({
				...user,
				registration_date: `${getDateTime(user?.created_at, 'mm dd yyyy')}, ${getDateTime(user?.created_at, '', false)}`,
			})),
			isLoading: false,
		};
	}
};
*/

export const useGetDashboardUser = ({ user_id, user = 'businesses' }) => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: () =>
			axiosPrivate
				.get(user === 'businesses' ? Appurls.dashboard.get_businesses(user_id) : Appurls.dashboard.get_interns(user_id))
				.then((response) => response.data),
		queryKey: ['dashboard-user', { user_id, user }],
	});
};

export const useGetDashboardUserApprovalLogs = ({ user_id }) => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: () => axiosPrivate.get(Appurls.dashboard.get_approval_logs(user_id)).then((response) => response.data),
		queryKey: ['dashboard-approval-logs', { user_id }],
	});
};

export const useGetFinances = ({ page = 1, page_size = 5, user = 'businesses' }) => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: () =>
			axiosPrivate
				.get(
					`${user === 'businesses' ? Appurls.dashboard.startup_packages : Appurls.dashboard.intern_packages}?page=${page}&page_size=${page_size}`
				)
				.then((response) => response.data),
		queryKey: ['dashboard-finances', { page, page_size, user }],
	});
};

export const useGetFinance = ({ user_id, user = 'businesses' }) => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: () =>
			axiosPrivate
				.get(user === 'businesses' ? Appurls.dashboard.get_startup_package(user_id) : Appurls.dashboard.get_intern_package(user_id))
				.then((response) => response.data),
		queryKey: ['dashboard-finance', { user_id, user }],
	});
};
