import styled from 'styled-components';
import { maxQuery } from '../../utilities';
import { StyledButton } from '../../ui/Button/styles';

export const Container = styled.div`
	padding: 2rem 6.25rem;
	padding: 2rem clamp(0rem, -3.4722rem + 11.1111vw, 6.25rem);

	> button {
		color: #252b33;
		font-size: 0.9375rem;
		font-weight: 600;
		border-radius: 0.25rem;
		border: 1px solid #ebebeb;
		background: #fff;
		padding: 1rem 1.5rem;
		display: inline-flex;
		margin-bottom: 1.3rem;
		align-items: center;
		gap: 1rem;

		svg {
			width: 0.8125rem;
			transform: rotate(90deg);
		}
	}

	> section {
		border-radius: 0.25rem;
		background: #fff;
		box-shadow: -9px 19px 40px 0px rgba(0, 0, 0, 0.02);
		padding: 1.5rem;
		display: grid;
		gap: 3rem;
		grid-template-columns: 31fr 19fr;
		min-height: calc(100vh - (5.3rem + 134px));

		&.exclude {
			grid-template-columns: 1fr;
		}

		${maxQuery('xl')} {
			grid-template-columns: 1fr;
			gap: 1rem;
			background-color: transparent;
			padding: 0;
		}

		center {
			display: grid;
			place-items: center;
		}
		> section {
			display: grid;
			gap: 1.5rem;
			background-color: #fff;

			${maxQuery('xl')} {
				padding: 1.5rem;
				border-radius: 0.25rem;
			}

			b {
				display: block;
				font-weight: 700;
				margin-bottom: 0.75rem;
			}
			.status {
				color: #017d34;
				font-size: 0.875rem;
				font-weight: 500;
				position: relative;
				padding-left: 0.9rem;

				&.in-progress {
					color: #c08030;

					::before {
						background-color: #c08030;
					}
				}

				::before {
					content: '';
					width: 0.4375rem;
					height: 0.4375rem;
					background-color: #017d34;
					border-radius: 50%;
					position: absolute;
					left: 0;
					top: 50%;
					transform: translateY(-50%);
				}
			}
			.title {
				margin: 1rem 0 0.5rem;
				color: #11071a;
				font-size: 1.5rem;
				font-size: clamp(1.25rem, 1.1111rem + 0.4444vw, 1.5rem);
				font-weight: 700;
				line-height: 132%;
			}
			.title + p {
				font-weight: 500;
				line-height: 140%;
			}
			.timeline {
				display: grid;
				grid-template-columns: 1fr 1fr;

				${maxQuery('xs')} {
					grid-template-columns: 1fr;
					gap: 1.5rem;
				}

				div {
					font-size: 1rem;
					font-weight: 600;
				}
			}
			.resource-group {
				display: grid;
				grid-template-columns: 1fr;
				/* grid-template-columns: 1fr 1fr; */

				${maxQuery('xs')} {
					grid-template-columns: 1fr;
					gap: 1.5rem;
				}

				.supervisor {
					div {
						display: flex;
						align-items: center;
						gap: 0.68rem;
						font-size: 1rem;
						font-weight: 600;

						img {
							width: 1.375rem;
							height: 1.375rem;
						}
					}
				}
			}
			.assignee div {
				display: flex;
				align-items: center;
				gap: 0.62rem;
				color: #494848;
				font-size: 0.875rem;
				font-weight: 500;
				line-height: 135%;
				border-radius: 0.25rem;
				border: 1px solid #dadada;
				padding: 0.62rem 0.69rem;
				width: fit-content;

				:not(:last-child) {
					margin-bottom: 0.5rem;
				}

				span {
					margin-right: auto;
				}
				svg {
					cursor: pointer;
				}
			}

			.attachment {
				overflow: hidden;
			}
			${StyledButton} {
				width: 100%;
				justify-content: center;
				margin-top: 1rem;
			}
		}
		aside {
			border-radius: 0.25rem;
			border: 1px solid rgba(18, 18, 18, 0.05);
			background: #fff;
			padding: 2rem 1.5rem 1.5rem;
			height: max-content;

			h4 {
				color: #11071a;
				font-family: 'Urbanist', sans-serif;
				font-size: 1.25rem;
				font-weight: 600;
				border-bottom: 1px solid #ebebeb;
				padding-bottom: 0.62rem;
				margin-bottom: 1.5rem;
			}

			.logs {
				display: grid;
				gap: 1rem;

				> div {
					display: flex;
					align-items: center;
					gap: 1rem;
					border-radius: 0.25rem;
					border: 1px solid rgba(18, 18, 18, 0.05);
					background: #fff;
					padding: 0.75rem 1rem;
					margin-right: auto;
					width: 100%;

					p {
						color: #11071a;
						font-weight: 600;
						display: -webkit-box;
						overflow: hidden;
						-webkit-line-clamp: 1;
						-webkit-box-orient: vertical;
					}
					span {
						color: #11071a;
						font-size: 0.875rem;
						font-weight: 500;
					}
				}
				> div > span {
					display: grid;
					place-items: center;
				}
				span:last-child {
					cursor: pointer;
					margin-left: auto;

					path {
						fill: #4060ea;
					}
				}
			}
		}
	}
`;
