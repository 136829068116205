import styled from 'styled-components';
import { StyledButton } from '../../../ui/Button/styles';
import { maxQuery } from '../../../utilities';

export const Container = styled.div`
	background: rgba(0, 0, 0, 0.5);
	display: grid;
	place-items: center;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 99;

	form {
		border-radius: 0.25rem;
		background: #fff;
		padding: 2rem;
		padding: 2rem clamp(1.5rem, 1.2222rem + 0.8889vw, 2rem);
		width: 53.625rem;
		max-width: calc(100vw - 2rem);
		max-height: calc(100vh - 2rem);
		overflow: auto;

		> svg {
			margin-left: auto;
			display: block;
			cursor: pointer;
			width: 1.25rem;
			width: clamp(1rem, 0.8611rem + 0.4444vw, 1.25rem);

			:active {
				outline: none;
			}
		}
		h2 {
			text-align: center;
			font-size: 2rem;
			font-size: clamp(1.75rem, 1.6111rem + 0.4444vw, 2rem);
			margin-top: 0.5rem;
			color: #070d19;
		}
		h2 + p {
			color: #a8a8a8;
			font-size: 1rem;
			font-size: clamp(0.75rem, 0.6111rem + 0.4444vw, 1rem);
			line-height: 120%;
			margin: 0.5rem 0 1.5rem;
			text-align: center;
		}

		.grid {
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 1.5rem;

			${maxQuery('md')} {
				grid-template-columns: 1fr;
			}
		}
		${StyledButton} {
			margin-top: 2rem;
			width: 100%;
			justify-content: center;
		}
	}
`;
