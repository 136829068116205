import { useRef, useState } from 'react';
import { EditTask as EditTaskSvg, TaskCheckmark, TaskClose } from '../../assets/svgs';
import { Button, InputGroup } from '../../ui';
import { useAddTask, useNotify } from '../../hooks';
import { handleApiError } from '../../utilities';
import { EditTask } from '../../components';

const AddTasks = ({ setStep, formData, setFormData, handleChange }) => {
	const errorRef = useRef();
	const notify = useNotify();

	const [editState, setEditState] = useState({
		openModal: false,
		task_name: '',
		task_desc: '',
		index: null,
	});
	const { mutate: addTasks, isLoading } = useAddTask();

	const handleSubmit = (event) => {
		event.preventDefault();

		if (formData.tasks.findIndex((task) => task.name === formData.task_name.trim()) >= 0) {
			errorRef.current.innerText = "You've already added this task";
		} else {
			setFormData((prev) => ({
				...prev,
				task_name: '',
				task_description: '',
				tasks: prev.task_name.trim() ? [...prev.tasks, { name: prev.task_name, description: prev.task_description.trim() }] : prev.tasks,
			}));
		}
	};
	const handleRemoveTask = (taskIndex) => {
		setFormData((prev) => ({ ...prev, tasks: prev.tasks.filter((_val, index) => index !== taskIndex) }));
	};
	// const handleShowSubscriptionPlan = () => {
	// 	if (
	// 		hasStartupPaid(user) ||
	// 		sessionStorage.getItem(SESSION_STORAGE_KEYS.has_closed_subscription) ||
	// 		user?.startup_profile_status !== 'APPROVED'
	// 	)
	// 		return;

	// 	let previousParams = getPreviousSearchParams(searchParams);

	// 	previousParams = { ...previousParams, [queryStrings.subscription_plan]: 'open' };
	// 	setSearchParams(previousParams, { replace: true });
	// };
	const handleNext = () => {
		if (formData.tasks.length === 0) {
			errorRef.current.innerText = 'You need to add at least one task';

			return;
		}

		addTasks(
			{ id: formData.project_id, data: { tasks: formData.tasks } },
			{
				onSuccess: () => {
					notify({ message: 'Tasks has been added to your project', status: 'success', toastOptions: { toastId: 'add_tasks_success' } });
					// handleShowSubscriptionPlan();
					setStep(3);
				},
				onError: (error) => notify({ message: handleApiError(error), status: 'error', toastOptions: { toastId: 'add_tasks_error' } }),
			}
		);
	};

	return (
		<>
			<div className="form">
				<h2>Add Tasks</h2>
				<p>Provide details about the task for this project</p>

				<form className="tasks-con" onSubmit={handleSubmit}>
					<InputGroup
						label="Task Name (press enter to add new task)"
						name="task_name"
						onChange={(event) => {
							errorRef.current.innerText = '';
							handleChange(event);
						}}
						placeholder="Task Name"
						value={formData.task_name}
						required
					/>
					<InputGroup
						label="Task Description"
						variant="textarea"
						name="task_description"
						onChange={(event) => {
							errorRef.current.innerText = '';
							handleChange(event);
						}}
						placeholder="Enter description of the task"
						value={formData.task_description}
						required
					/>
					<div className="error" ref={errorRef}></div>

					<Button type="submit" variant="secondary" onClick={handleSubmit}>
						Add new task
					</Button>

					{formData.tasks.length > 0 ? (
						<div>
							<h4>{formData.tasks.length === 1 ? '1 Task' : `${formData.tasks.length} Tasks`}</h4>

							{formData.tasks.map((task, index) => (
								<div className="task" key={task.name}>
									<TaskCheckmark /> {task.name}
									<EditTaskSvg onClick={() => setEditState({ openModal: true, task_name: task.name, task_desc: task.description, index })} />
									<TaskClose tabIndex={0} onClick={() => handleRemoveTask(index)} />
								</div>
							))}
						</div>
					) : null}
				</form>

				<div className="btn-group">
					<Button style={{ gridColumn: '1/3' }} onClick={handleNext} loading={isLoading}>
						Add Tasks
					</Button>
				</div>
			</div>

			{editState.openModal ? (
				<EditTask
					taskName={editState.task_name}
					taskDesc={editState.task_desc}
					handleClose={() =>
						setEditState({
							openModal: false,
							task_name: '',
							task_desc: '',
							index: null,
						})
					}
					handleSubmit={(data) => {
						const { task_name, task_description } = data;

						let prevTasks = [...formData.tasks];
						prevTasks.splice(editState.index, 1, { name: task_name, description: task_description });

						setFormData((prev) => ({
							...prev,
							tasks: prevTasks,
						}));
						setEditState({
							openModal: false,
							task_name: '',
							task_desc: '',
							index: null,
						});
					}}
				/>
			) : null}
		</>
	);
};

export default AddTasks;
