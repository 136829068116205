import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { queryStrings } from '../../constants';
import { ApplicantAvatar, ApplicantEye, ChevronDown, EyeOpen, LogIcon, ProjectEmpty } from '../../assets/svgs';
import { Container } from './styles';
import { Button, FileDownloadGroup, Loader, Tag } from '../../ui';
// import { Supervisor } from '../../assets/images';
import { useGetApplicant, useGetTask, useNotify, usePatchTask } from '../../hooks';
import { getDateTime, getPreviousSearchParams, handleApiError } from '../../utilities';
import { useState } from 'react';
import { LogSummary } from '../../components/Modals';
import { Tags } from '../../ui/Tag/styles';

const ProjectTaskDetails = () => {
	const navigate = useNavigate();
	const [logState, setLogState] = useState({
		isOpen: false,
		log: {},
	});
	const notify = useNotify();
	const { taskId } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const { data: task, isLoading, isError, refetch: refetchTask } = useGetTask({ taskId });
	const result = useGetApplicant({
		applicantId: task?.assigned_to,
		enabled: task?.assigned_to ? true : false,
		shouldFetchExperience: false,
	});
	const applicant = result[0]?.data;
	const isLoadingAssignee = result[0]?.isLoading === undefined ? true : result[0]?.isLoading;

	const { mutate: patchTask, isLoading: isTaskPatching } = usePatchTask();

	const handleViewApplicant = (id) => {
		let previousParams = getPreviousSearchParams(searchParams);

		previousParams = { ...previousParams, [queryStrings.applicantId]: id };
		setSearchParams(previousParams, { replace: true });
	};
	const handleCompleteTask = (id, is_completed) => {
		patchTask(
			{ id, data: { is_completed } },
			{
				onSuccess: () => {
					refetchTask();
					notify({ message: 'Task has been marked as completed', status: 'success', toastOptions: { toastId: 'task_patch_success' } });
				},
				onError: (error) => notify({ message: handleApiError(error), status: 'error', toastOptions: { toastId: 'task_patch_error' } }),
			}
		);
	};
	const handleRemoveTags = (id, tags) => {
		patchTask({ id, data: { tags } }, { onSuccess: () => refetchTask() });
	};
	const handleViewLog = (log) => {
		setLogState({
			isOpen: true,
			log,
		});
	};

	return (
		<Container $sector="e-commerce">
			<button onClick={() => navigate(-1)}>
				<ChevronDown /> Back to Project
			</button>

			{isLoading ? (
				<section className="exclude">
					<section>
						<center>
							<Loader />
						</center>
					</section>
				</section>
			) : isError ? (
				<section className="exclude">
					<section>
						<center>
							<div>
								<ProjectEmpty />
								<p>Unable to fetch task details at the moment</p>
							</div>
						</center>
					</section>
				</section>
			) : (
				<section>
					<section>
						<article>
							<div className={`status ${task?.is_completed ? '' : 'in-progress'}`}>{task?.is_completed ? 'Completed' : 'In progress'}</div>

							<div className="title">{task?.name}</div>
							<p>{task?.description}</p>
						</article>
						<div className="timeline">
							<div>
								<b>Start date</b>
								<span>{task?.start_date ? getDateTime(task?.start_date) : 'Not set'}</span>
							</div>
							<div>
								<b>End date</b>
								<span>{task?.end_date ? getDateTime(task?.end_date) : 'Not set'}</span>
							</div>
						</div>
						<div className="resource-group">
							{
								//<div className="supervisor">
								//	<b>Project supervisor</b>
								//	<div>
								//		{task?.project_supervisor ? (
								//			<>
								//				{/*<img src={Supervisor} alt="" />*/} <span>{task?.project_supervisor}</span>
								//			</>
								//		) : (
								//			'Not set'
								//		)}{
								//	</div>
								// </div>
							}
							<div className="assignee">
								<b>Assigned To</b>

								{task?.assigned_to ? (
									isLoadingAssignee ? (
										'Loading...'
									) : (
										<div>
											<img src={ApplicantAvatar} alt="" />
											<span>{applicant?.name}</span>
											<ApplicantEye onClick={() => handleViewApplicant(applicant?.id)} />
										</div>
									)
								) : (
									'N/A'
								)}

								{/* {task?.assigned_to ? <SelectedApplicants applicants={[task?.assigned_to]} handleClick={handleViewApplicant} /> : 'Unassigned'} */}
							</div>
						</div>
						<div className="attachment">
							<b>Attachments</b>

							{task?.docs.length ? (
								<FileDownloadGroup fileName={task?.docs[0]?.file_name} url={task?.docs[0]?.url} fileSizeInKb={task?.docs[0]?.file_size_in_kb} />
							) : (
								'No file uploaded'
							)}
						</div>

						<div>
							<b>Tags</b>
							{task?.tags.length ? (
								<Tags>
									{task?.tags.map((tag) => (
										<Tag
											key={tag}
											tag={tag}
											handleRemove={() =>
												handleRemoveTags(
													task?.id,
													task?.tags?.filter((filterTag) => filterTag !== tag)
												)
											}
										/>
									))}
								</Tags>
							) : (
								'No tags added'
							)}
						</div>

						<Button loading={isTaskPatching} disabled={task?.is_completed} onClick={() => handleCompleteTask(task?.id, !task?.is_completed)}>
							Mark complete
						</Button>
					</section>

					<aside>
						<h4>Logs</h4>

						<div className="logs">
							{task?.submitted_logs.length
								? task?.submitted_logs.map((log) => (
										<div key={log?.id}>
											<span>
												<LogIcon />
											</span>

											<div>
												<p>{log?.log_name}</p>
												<span>{getDateTime(log?.created_at, 'mm dd yyyy')}</span>
											</div>

											<span>
												<EyeOpen onClick={() => handleViewLog(log)} />
											</span>
										</div>
								  ))
								: 'No logs submitted'}
						</div>

						{logState.isOpen ? <LogSummary log={logState.log} handleClose={() => setLogState({ isOpen: false, log: {} })} /> : null}
					</aside>
				</section>
			)}
		</Container>
	);
};
export default ProjectTaskDetails;
