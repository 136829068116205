import { useCallback, useRef, useState } from 'react';
import { Container } from './styles';
import { Loader, Pagination, PalletList, SearchBar } from '../../../../ui';
import { useGetBusinessStats, useGetDashboardUsers } from '../../../../hooks';
import { useSearchParams } from 'react-router-dom';
import { getPreviousSearchParams } from '../../../../utilities';
import { businessesFilters, queryStrings, statisticsFilters } from '../../../../constants';
import {
	DashboardApprovedBusiness,
	DashboardBusiness,
	DashboardDeclinedBusiness,
	DashboardIncompleteBusiness,
	DashboardPendingBusiness,
	ProjectEmpty,
} from '../../../../assets/svgs';
import { BusinessTable } from '../../components';
import { Filter } from '../../../../components';
// import { CSVLink } from 'react-csv';

const Businesses = () => {
	const pageSize = useRef(10);
	const [page, setPage] = useState(1);
	const [searchParams, setSearchParams] = useSearchParams();
	const [formData, setFormData] = useState({
		date_range: 'all_time',
		approval_status: 'all',
		search: '',
		filter: (() => {
			let state = {};
			businessesFilters.forEach((option) => (state[option?.name] = { value: '', queryKey: option?.queryKey }));

			return state;
		})(),
	});

	const { data: stats, isLoading: statsLoading } = useGetBusinessStats({
		extraQuery:
			`date_created=${formData.date_range !== 'all_time' ? formData.date_range : ''}` +
			Object.values(formData.filter).reduce((prev, current) => (current?.value ? `${prev}&${current?.queryKey}=${current?.value}` : prev), ''),
	});
	const {
		data: users,
		isLoading,
		isError,
	} = useGetDashboardUsers({
		page,
		page_size: pageSize.current,
		search: formData.search,
		user: 'businesses',
		extraQuery:
			`&date_created=${formData.date_range !== 'all_time' ? formData.date_range : ''}` +
			(formData.approval_status !== 'all' ? `&startup_profile_status=${formData.approval_status}` : '') +
			Object.values(formData.filter).reduce((prev, current) => (current?.value ? `${prev}&${current?.queryKey}=${current?.value}` : prev), ''),
	});
	// const { data: csvUsers, isLoading: isLoadingCsvUsers } = useGetDashboardUsersForExport({
	// 	page: 1,
	// 	page_size: 999,
	// 	search: '',
	// 	user: 'businesses',
	// });

	const statsData = [
		{
			title: 'Total Businesses',
			body: stats?.data?.total || 0,
			icon: <DashboardBusiness />,
		},
		{
			title: 'Approved Businesses',
			body: stats?.data?.approved || 0,
			icon: <DashboardApprovedBusiness />,
		},
		{
			title: 'Awaiting Approval',
			body: stats?.data?.awaiting_approval || 0,
			icon: <DashboardPendingBusiness />,
		},
		{
			title: 'Incomplete Profile',
			body: stats?.data?.incomplete_profile || 0,
			icon: <DashboardIncompleteBusiness />,
		},
		{
			title: 'Declined Businesses',
			body: stats?.data?.declined || 0,
			icon: <DashboardDeclinedBusiness />,
		},
	];

	const handleItemClick = (id) => {
		let previousParams = getPreviousSearchParams(searchParams);

		delete previousParams[queryStrings.applicantId];
		delete previousParams[queryStrings.businessId];

		previousParams = { ...previousParams, [queryStrings.businessId]: id };
		setSearchParams(previousParams, { replace: true });
	};

	const handleChange = useCallback((event, name, value) => {
		name = name || event?.target.name || '';
		value = value || event?.target.value || '';

		setFormData((prev) => ({ ...prev, [name]: value }));
	}, []);

	// const headers = [
	// 	{ label: "Company's Name", key: 'company_name' },
	// 	{ label: 'Business Plan', key: 'business_plan' },
	// 	{ label: 'Email Address', key: 'email' },
	// 	{ label: 'Contact Number', key: 'company_phone' },
	// 	{ label: 'Registration Number', key: 'company_reg_no' },
	// 	{ label: 'Introduction', key: 'about' },
	// 	{ label: 'Sector', key: 'sector' },
	// 	{ label: 'Website url', key: 'website_url' },
	// 	{ label: 'Company size', key: 'size' },
	// 	{ label: 'Approval Status', key: 'startup_profile_status' },
	// 	{ label: 'Registration Date', key: 'registration_date' },
	// ];

	// const data = [
	// 	{
	// 		id: 'f02af620-f9db-46cf-8739-64225d4b18c2',
	// 		verified: true,
	// 		registration_date: "getDateTime(user?.created_at, 'mm dd yyyy'), getDateTime(user?.created_at, '', false)",
	// 		created_at: '2024-12-11T23:48:43.577261Z',
	// 		role: ['STARTUP'],
	// 		email: 'info@borderlessanalysts.com',
	// 		startup_profile_status: 'APPROVED',
	// 		company_name: 'Borderless Analysts',
	// 		company_reg_no: '7877362',
	// 		company_phone: '+447732034572',
	// 		sector: 'Healthcare Technology (HealthTech)',
	// 		size: '0 - 10',
	// 		website_url: null,
	// 		about:
	// 			'We specialize in Business Analysis, Financial Analysis, Data Analysis, HR Analysis, and Change Management. Our mission is to empower organizations to make informed decisions, drive growth, and achieve their strategic goals. With a focus on innovative strategies and data-driven insights, we are committed to transforming your business challenges into opportunities for success.',
	// 		amount_paid: 0.0,
	// 		business_plan: [],
	// 		sub_start: '',
	// 		sub_end: '',
	// 	},
	// ];

	return (
		<Container>
			<div className="header">
				<div>
					<h4>Business</h4>
					{/* <p>Update your photo and personal details</p> */}
				</div>

				<div className="header-controls">
					<button className={formData.date_range === 'all_time' ? 'active' : ''} onClick={() => handleChange(null, 'date_range', 'all_time')}>
						All Time
					</button>
					{statisticsFilters.map((date_range) => (
						<button
							className={formData.date_range === date_range.queryKey ? 'active' : ''}
							onClick={() => handleChange(null, 'date_range', date_range.queryKey)}
						>
							{date_range.name}
						</button>
					))}
				</div>
			</div>

			<PalletList loading={statsLoading} data={statsData} />

			<div className="table-header">
				<div>
					<h4>Business Listings</h4>
					{/* <p>Update your photo and personal details</p> */}
				</div>

				<div className="filter-con">
					<SearchBar name="search" placeholder="Search through applicants" handleChange={handleChange} value={formData.search} />
					<Filter name="filter" onChange={handleChange} value={formData.filter} options={businessesFilters} />
					{/* {isLoadingCsvUsers ? (
						<Button disabled={isLoadingCsvUsers} loading={isLoadingCsvUsers} style={{ padding: '0 1rem', height: '51px' }}>
							Export To CSV
						</Button>
					) : !csvUsers ? (
						<Button disabled style={{ padding: '0 1rem', height: '51px' }}>
							Export To CSV
						</Button>
					) : (
						<CSVLink data={csvUsers} headers={headers} filename={'Startup_list_descinder.csv'} className="btn btn-primary" target="_blank">
							<Button disabled={isLoadingCsvUsers} loading={isLoadingCsvUsers} style={{ padding: '0 1rem', height: '51px' }}>
								Export To CSV
							</Button>
						</CSVLink>
					)} */}
				</div>
			</div>

			<div className="controls">
				<button className={formData.approval_status === 'all' ? 'active' : ''} onClick={() => handleChange(null, 'approval_status', 'all')}>
					All
				</button>
				<button
					className={formData.approval_status === 'approved' ? 'active' : ''}
					onClick={() => handleChange(null, 'approval_status', 'approved')}
				>
					Approved
				</button>
				<button
					className={formData.approval_status === 'awaiting_approval' ? 'active' : ''}
					onClick={() => handleChange(null, 'approval_status', 'awaiting_approval')}
				>
					Awaiting Approval
				</button>
				<button
					className={formData.approval_status === 'pending' ? 'active' : ''}
					onClick={() => handleChange(null, 'approval_status', 'pending')}
				>
					Incomplete Profile
				</button>
				<button
					className={formData.approval_status === 'declined' ? 'active' : ''}
					onClick={() => handleChange(null, 'approval_status', 'declined')}
				>
					Declined
				</button>
			</div>

			{isLoading ? (
				<center>
					<Loader />
				</center>
			) : isError ? (
				<center>
					<div>
						<ProjectEmpty />
						<p className="error-message">Unable to fetch businesses at the moment</p>
					</div>
				</center>
			) : !users?.results || users?.results?.length === 0 ? (
				<div className="empty-state">
					<ProjectEmpty />
					<p className="error-message">No business to display</p>
				</div>
			) : (
				<>
					<BusinessTable page={page} users={users} handleItemClick={(id) => handleItemClick(id)} />

					<Pagination page={page} setPage={setPage} maxPages={Math.ceil(users?.total / pageSize.current)} disabled={isLoading} />
				</>
			)}
		</Container>
	);
};
export default Businesses;
