import styled from 'styled-components';
import { StyledButton } from '../../../../ui/Button/styles';

export const Container = styled.div`
	background: rgba(0, 0, 0, 0.5);
	display: grid;
	place-items: center;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 99;

	> center {
		height: 30rem;
		display: flex;
		flex-direction: column;
		padding-top: 2rem !important;

		> div {
			flex: 1;
			display: grid;
			place-items: center;
		}
	}
	> form,
	> div,
	> center {
		border-radius: 0.25rem;
		background: #fff;
		padding: 0 2rem 2rem;
		padding: 0 clamp(1.5rem, 1.2222rem + 0.8889vw, 2rem) 2rem;
		width: 37.9375rem;
		max-width: calc(100vw - 2rem);
		max-height: calc(100vh - 2rem);
		overflow: auto;

		.header {
			position: sticky;
			top: 0;
			background-color: #fff;
			z-index: 2;
			padding-top: 2rem;

			h2 {
				text-align: center;
				font-size: 2rem;
				font-size: clamp(1.75rem, 1.6111rem + 0.4444vw, 2rem);
				margin: 0.5rem 0 1.5rem;
				color: #070d19;
			}
			hr {
				border-top: 1px solid #ebebeb;
			}
		}
		.header svg,
		> svg {
			margin-left: auto;
			display: block;
			cursor: pointer;
			width: 1.25rem;
			width: clamp(1rem, 0.8611rem + 0.4444vw, 1.25rem);

			:active {
				outline: none;
			}
		}
		article {
			border-radius: 0.25rem;
			border: 1px solid rgba(18, 18, 18, 0.03);
			padding: 1rem;
			margin: 1rem 0;

			:last-child {
				margin-bottom: 0;
			}
			&.flexed {
				display: flex;
				justify-content: space-between;
				gap: 0.5rem;

				> div:nth-child(2) {
					text-align: right;
				}
			}

			b {
				display: block;
				margin-bottom: 0.63rem;
			}

			p {
				color: #070d19;
				font-size: 0.875rem;
				line-height: 140%;
			}
		}
		.internship {
			display: flex;
			justify-content: space-between;
			text-align: center;
		}
		${StyledButton} {
			width: 100%;
			margin-top: 2rem;
		}
	}
`;
