import styled, { css } from 'styled-components';
import { maxQuery } from '../../utilities';
import { StyledButton } from '../../ui/Button/styles';

export const Container = styled.div`
	padding: 2rem 6.25rem;
	padding: 2rem clamp(1rem, -1.9167rem + 9.3333vw, 6.25rem);

	> button {
		color: #252b33;
		font-size: 0.9375rem;
		font-weight: 600;
		border-radius: 0.25rem;
		border: 1px solid #ebebeb;
		background: #fff;
		padding: 1rem 1.5rem;
		display: inline-flex;
		margin-bottom: 1.3rem;
		align-items: center;
		gap: 1rem;

		svg {
			width: 0.8125rem;
			transform: rotate(90deg);
		}
	}
	center {
		height: calc(100vh - 15rem);
		display: grid;
		place-items: center;
		background-color: #fff;
		border-radius: 0.125rem;
		padding: 1rem;
	}
	.empty-state {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: calc(100vh - 15rem);
		background-color: #fff;
		border-radius: 0.125rem;
		padding: 1rem;

		p {
			color: #1e1e1e;
			font-size: 0.875rem;
			font-weight: 600;
			line-height: 135%;
			margin-top: 1rem;
		}
		span {
			text-transform: capitalize;
		}
	}
	> section {
		border-radius: 0.25rem;
		background: #fff;
		box-shadow: -9px 19px 40px 0px rgba(0, 0, 0, 0.02);
		padding: 1.5rem;
		display: grid;
		gap: 3rem;
		grid-template-columns: 31fr 19fr;
		min-height: calc(100vh - (5.3rem + 134px));

		${maxQuery('xl')} {
			grid-template-columns: 1fr;
			gap: 1rem;
			background-color: transparent;
			padding: 0;
		}

		> section {
			background: #fff;
			overflow: hidden;

			${maxQuery('xl')} {
				padding: 1.5rem;
				border-radius: 0.25rem;
			}

			.status {
				color: #017d34;
				font-size: 0.875rem;
				font-weight: 500;
				position: relative;
				padding-left: 0.9rem;
				text-transform: capitalize;

				&.pending {
					color: #c08030;

					::before {
						background-color: #c08030;
					}
				}

				::before {
					content: '';
					width: 0.4375rem;
					height: 0.4375rem;
					background-color: #017d34;
					border-radius: 50%;
					position: absolute;
					left: 0;
					top: 50%;
					transform: translateY(-50%);
				}
			}
			.title {
				display: flex;
				gap: 0.5rem;
				align-items: center;
				flex-wrap: wrap;
				margin: 1rem 0 0.5rem;

				b {
					color: #11071a;
					font-size: 1.5rem;
					font-size: clamp(1.25rem, 1.1111rem + 0.4444vw, 1.5rem);
					font-weight: 700;
					line-height: 132%;
				}

				span {
					background: #fcf8f3;
					color: #c08030;
					font-size: 0.75rem;
					font-weight: 700;
					line-height: 120%;
					text-transform: uppercase;
					border-radius: 6.25rem;
					padding: 0.3125rem 0.625rem;

					${({ $sector }) =>
						$sector?.toLowerCase() === 'gaming' &&
						css`
							background: #ebf9e9;
							color: #42b431;
						`}
					${({ $sector }) =>
						$sector?.toLowerCase() === 'payment' &&
						css`
							background: #e5f3fe;
							color: #2299f6;
						`}
				}
			}
			.title + p {
				font-weight: 500;
				line-height: 140%;
				border-bottom: 1px solid #ebebeb;
				padding-bottom: 1.5rem;
			}

			.attachments {
				padding-bottom: 1.5rem;
				border-bottom: 1px solid #ebebeb;
				overflow: hidden;

				h6 {
					color: #000;
					font-family: 'Urbanist', sans-serif;
					font-size: 1rem;
					margin: 1.25rem 0 1.5rem;
					display: block;
				}
			}
		}

		aside {
			border-radius: 0.25rem;
			border: 1px solid rgba(18, 18, 18, 0.05);
			background: #fff;
			padding: 2rem 1.5rem 1.5rem;
			display: grid;

			h4 {
				color: #11071a;
				font-family: 'Urbanist', sans-serif;
				font-size: 1.25rem;
				font-weight: 600;
				border-bottom: 1px solid #ebebeb;
				padding-bottom: 0.62rem;
				margin-bottom: 1.5rem;
			}
			h6 {
				color: #a1a0a0;
				font-family: 'Urbanist', sans-serif;
				font-size: 0.875rem;
				font-weight: 600;
			}
			.timeline {
				display: flex;
				justify-content: space-between;
				gap: 1rem;
				flex-wrap: wrap;

				div {
					font-size: 1rem;
					font-weight: 600;

					h6 {
						margin-bottom: 0.5rem;
					}
				}
			}
			.resource-tags h6 {
				margin: 1.88rem 0 0.62rem;
			}
			.supervisor {
				margin-top: 1.87rem;

				h6 {
					margin-bottom: 0.87rem;
				}

				div {
					display: flex;
					align-items: center;
					gap: 0.68rem;
					font-size: 1rem;
					font-weight: 600;

					img {
						width: 1.375rem;
						height: 1.375rem;
					}
				}
			}
			.completion-status {
				margin: 1.87rem 0 3rem;

				h6 {
					margin-bottom: 0.87rem;
				}

				div {
					color: #a1a0a0;
					font-size: 0.875rem;
					font-weight: 600;
					display: flex;
					align-items: center;
					gap: 1rem;
				}
			}
			${StyledButton} {
				width: 100%;
				justify-content: center;
				margin-top: auto;
			}
		}
	}
`;

export const Range = styled.div`
	flex: 1;
	border-radius: 0.625rem;
	background: #f6f7fe;
	height: 0.625rem;
	position: relative;

	::before {
		content: '';
		position: absolute;
		/* inset: 0 ${({ $percent }) => `${100 - $percent}%`} 0 0; */
		top: 0;
		left: 0;
		right: ${({ $percent }) => `${100 - $percent}%`};
		bottom: 0;
		border-radius: 0.625rem;
		background: #4060ea;
	}
`;
