import { NavLink, Outlet } from 'react-router-dom';
import { Container } from './styles';
import { Approutes } from '../../constants';
// import { useDecodedUser } from '../../hooks';
// import { isStartUp } from '../../utilities';

const Settings = () => {
	// const user = useDecodedUser();

	return (
		<Container>
			<div className="header">
				<h4>Settings</h4>
			</div>

			<div className="controls">
				<NavLink to={Approutes.settings.password}>Password</NavLink>
				{/* {isStartUp(user) ? <NavLink to={Approutes.settings.billing}>Plan & Billing</NavLink> : null} */}
				<NavLink to={Approutes.settings.notifications}>Notifications</NavLink>
				{/* <NavLink to={Approutes.settings.help}>Help</NavLink> */}
			</div>

			<Outlet />
		</Container>
	);
};

export default Settings;
