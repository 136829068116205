import { FileDoc } from '../../../../assets/svgs';
import { getDateDifference, getDateTime, getInitials } from '../../../../utilities';
import { Container } from './styles';

const UserMessage = ({ id, profilePicture, name, dateTime, message, isMine, media }) => {
	const dateDifference = getDateDifference(new Date(), dateTime) || 0;
	const dateTimeOfMessage = dateDifference > 0 ? getDateTime(dateTime, null, false) : getDateTime(dateTime, null, false);

	return (
		<Container id={id} className={isMine ? 'mine' : ''}>
			<div className="text-section">
				{profilePicture ? <img src={profilePicture} alt="" /> : <div className="initials">{getInitials(name)}</div>}

				<div className={`${isMine ? 'mine' : ''} text-con`}>
					{/* <div className="name-date-group">
					<b className="name">{name}</b>
					<span className="date">{dateTimeOfMessage}</span>
					</div> */}

					<div className="message-con" dangerouslySetInnerHTML={{ __html: message }} />
					<span className="date">{dateTimeOfMessage}</span>
				</div>
			</div>

			{media?.length ? (
				<div className="media-section">
					{media.map((item, index) => (
						<a key={index} href={item.url} target="_blank" rel="noreferrer">
							{['gif', 'png', 'jpeg', 'jpg'].includes(
								item.file_name.substring(item.file_name.lastIndexOf('.') + 1).toLowerCase()
							) ? (
								<img src={item.url} alt={item.file_name} className="preview-img" />
							) : (
								<div>
									<FileDoc />
								</div>
							)}
							<div>
								<p>{item.file_name}</p>
								<span>{item.file_name.substring(item.file_name.lastIndexOf('.') + 1).toUpperCase()} File</span>
							</div>
						</a>
					))}
				</div>
			) : null}
		</Container>
	);
};
export default UserMessage;
