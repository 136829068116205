import { Outlet, useSearchParams } from 'react-router-dom';
import { useDecodedUser } from '../../hooks';
import { PageNotFound } from '../../views';
import { queryStrings } from '../../constants';
import { ApplicantDetails } from '../Modals';
import { isStartUp } from '../../utilities';

const StartupOnly = () => {
	const user = useDecodedUser();
	const [searchParams] = useSearchParams();
	// const { pathname } = useLocation();

	// useEffect(() => {
	// 	const ALLOWED_ROUTES = [Approutes.startup.projects.index, Approutes.startup.early_starters];

	// 	if (
	// 		hasStartupPaid(user) ||
	// 		sessionStorage.getItem(SESSION_STORAGE_KEYS.has_closed_subscription) ||
	// 		!ALLOWED_ROUTES.includes(pathname) ||
	// 		user?.startup_profile_status !== 'APPROVED'
	// 	)
	// 		return;

	// 	let previousParams = getPreviousSearchParams(searchParams);

	// 	previousParams = { ...previousParams, [queryStrings.subscription_plan]: 'open' };
	// 	setSearchParams(previousParams, { replace: true });
	// }, [user, searchParams, setSearchParams, pathname]);

	return isStartUp(user) ? (
		<>
			<Outlet />

			{searchParams.get(queryStrings.applicantId) ? <ApplicantDetails /> : null}
		</>
	) : (
		<PageNotFound />
	);
};

export default StartupOnly;
